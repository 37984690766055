<template>
    <div>
    <!--qr-code :text="qr_url" color="#000" bg-color="#fff" error-level="H" class="qr-code"></qr-code-->
    <h3 class="text-center">Events</h3>
    <!--p>{{ events }}</p-->
    <ul>
        <li v-for="event in events" :key="event.id">
            {{ event.slug }}
        </li>
    </ul>
    <h3 class="text-center">Items</h3>
    <!--p>{{ loadedItems }}</p-->
    <ul>
        <li v-for="item in loadedItems" :key="item.id">
            {{ item.description }}
        </li>
    </ul>
    <h3 class="text-center">Boxes</h3>
    <!--p>{{ loadedBoxes }}</p-->
    <ul>
        <li v-for="box in loadedBoxes" :key="box.id">
            {{ box.name }}
        </li>
    </ul>
    <h3 class="text-center">Issues</h3>
    <!--p>{{ issues }}</p-->
    <ul>
        <li v-for="issue in tickets" :key="issue.id">
            {{ issue.id }}
        </li>
    </ul>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import Table from '@/components/Table';

export default {
    name: 'Debug',
    components: {Table},
    computed: {
        ...mapState(['events', 'loadedItems', 'loadedBoxes', 'tickets']),
        qr_url() {
            return window.location.href;
        }
    },
    methods: {
        ...mapActions(['changeEvent', 'loadTickets']),

    },
    mounted() {
        this.loadTickets();
    }
};
</script>

<style>
.qr-code img {
    border: #fff solid 7px
}
</style>