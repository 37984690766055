<template>
    <Table
        :columns="['slug', 'name']"
        :items="events"
        :keyName="'slug'"
    >
        <template #actions="{ item }">
            <div class="btn-group">
                <button class="btn btn-secondary" @click.stop="changeEvent(item)">
                    <font-awesome-icon icon="archive"/>
                    use
                </button>
                <button class="btn btn-danger" @click.stop="">
                    <font-awesome-icon icon="trash"/>
                    delete
                </button>
            </div>
        </template>
    </Table>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import Table from '@/components/Table';

export default {
    name: 'Events',
    components: {Table},
    computed: mapState(['events']),
    methods: mapActions(['changeEvent']),
};
</script>

<style scoped>

</style>