import {createRouter, createWebHistory} from 'vue-router'
import store from '@/store';

import Items from './views/Items';
import Boxes from './views/Boxes';
import Files from './views/Files';
import HowTo from './views/HowTo';
import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import Debug from "@/views/admin/Debug.vue";
import Tickets from "@/views/Tickets.vue";
import Ticket from "@/views/Ticket.vue";
import Admin from "@/views/admin/Admin.vue";
import Empty from "@/views/Empty.vue";
import Events from "@/views/admin/Events.vue";
import AccessControl from "@/views/admin/AccessControl.vue";
import {default as BoxesAdmin} from "@/views/admin/Boxes.vue"
import Shipping from "@/views/admin/Shipping.vue";

const routes = [
    {path: '/', redirect: '/37C3/items', meta: {requiresAuth: false}},
    {path: '/login/', name: 'login', component: Login, meta: {requiresAuth: false}},
    {path: '/register/', name: 'register', component: Register, meta: {requiresAuth: false}},
    {path: '/howto/', name: 'howto', component: HowTo, meta: {requiresAuth: true}},
    {
        path: '/:event/items/', name: 'items', component: Items, meta:
            {requiresAuth: true, requiresPermission: 'view_item'}
    },
    {
        path: '/:event/item/:uid/', name: 'item', component: Items, meta:
            {requiresAuth: true, requiresPermission: 'view_item'}
    },
    {
        path: '/:event/boxes/', name: 'boxes', component: Boxes, meta:
            {requiresAuth: true, requiresPermission: 'view_container'}
    },
    {
        path: '/:event/box/:uid/', name: 'box', component: Boxes, meta:
            {requiresAuth: true, requiresPermission: 'view_container'}
    },
    {
        path: '/:event/tickets/', name: 'tickets', component: Tickets, meta:
            {requiresAuth: true, requiresPermission: 'view_issuethread'}
    },
    {
        path: '/:event/ticket/:id/', name: 'ticket', component: Ticket, meta:
            {requiresAuth: true, requiresPermission: 'view_issuethread'}
    },
    {
        path: '/admin/', component: Admin, meta:
            {requiresAuth: true, requiresPermission: 'delete_event'},
        children: [
            {
                path: 'files/', name: 'files', component: Files, meta:
                    {requiresAuth: true, requiresPermission: 'delete_event'}
            },
            {
                path: 'events/', name: 'events', component: Events, meta:
                    {requiresAuth: true, requiresPermission: 'delete_event'}
            },
            {
                path: '', name: 'admin', component: Debug, meta:
                    {requiresAuth: true, requiresPermission: 'delete_event'}
            },
            {
                path: 'users/', name: 'users', component: AccessControl, meta:
                    {requiresAuth: true, requiresPermission: 'delete_event'}
            },
            {
                path: 'boxes/', name: 'admin_boxes', component: BoxesAdmin, meta:
                    {requiresAuth: true, requiresPermission: 'delete_event'}
            },
            {
                path: 'shipping/', name: 'shipping', component: Shipping, meta:
                    {requiresAuth: true, requiresPermission: 'delete_event'}
            },
        ]
    },
    {path: '/user', name: 'user', component: Empty, meta: {requiresAuth: true}},
];

const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: "active",
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !store.getters.isLoggedIn) {
        console.log("Not logged in, redirecting to login page")
        next({
            name: 'login',
            query: {redirect: to.fullPath},
        })
    } else if (to.meta.requiresPermission && !store.getters.checkPermission(to.params.event || "*", to.meta.requiresPermission)) {
        console.log("Not enough permissions, redirecting to empty page")
        next({
            path: '/user',
        })
    } else {
        next()
    }
});

router.afterEach((to, from) => {
    if (to.params.event && to.params.event !== store.state.lastEvent) {
        //console.log('update last event', to.params.event);
        store.commit('updateLastEvent', to.params.event);
    }
});

export default router;
